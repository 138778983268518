<template>
  <div class="setting-filiale-sci">
    <!-- MODAL CREATION CONFIGURATION  -->
    <v-dialog
      v-model="addConfigurationModal"
      persistent
      max-width="98%"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add custom-top-modal"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ typeConfigToUse == 'add' ? 'Ajouter' : 'Dupliquer' }} une
            configuration
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalConfig('add', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div v-if="getConfigurationError" class="message-error-modal">
            <ul v-if="Array.isArray(getConfigurationError)" class="mb-0">
              <li v-for="(e, index) in getConfigurationError" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>
              {{ getConfigurationError }}
            </div>
          </div>
          <div v-if="getErrorForSpecific" class="message-error-modal mt-2 ">
            <v-tooltip bottom color="#311B92">
              <!-- {{ getErrorForSpecific.error }}
              <font-awesome-icon class="info-icon" icon="info" /> -->
              <template v-slot:activator="{ on, attrs }">
                {{ getErrorForSpecific.error }}
                <div size="sm" v-on="on" v-bind="attrs">
                  <font-awesome-icon icon="info" />
                </div>
              </template>
              <span>
                <ul class="mb-0" style="list-style-type: none;">
                  <li
                    v-for="(error, index) in getErrorForSpecific.acheteurs"
                    :key="index"
                  >
                    {{ error }}
                  </li>
                </ul>
              </span>
            </v-tooltip>
          </div>
          <form class="form-add">
            <v-row>
              <v-col lg="7" md="12" sm="12" class="pr-4">
                <v-row>
                  <v-col cols="2"> Famille:</v-col>
                  <v-col cols="2">
                    <v-checkbox
                      label="Support"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="'support'"
                      :true-value="'support'"
                      :false-value="null"
                      hide-details
                      @change="initialFamilly"
                      v-model="configurationToAdd.main_family"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="2">
                    <v-checkbox
                      label="Master"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="'master'"
                      :true-value="'master'"
                      :false-value="null"
                      hide-details
                      @change="initialFamilly"
                      v-model="configurationToAdd.main_family"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="2">
                    <v-checkbox
                      @change="
                        initialFamilly();
                        configurationToAdd.filiale_id = 291;
                      "
                      label="Commercial"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="'commercial'"
                      :true-value="'commercial'"
                      :false-value="null"
                      hide-details
                      v-model="configurationToAdd.main_family"
                    ></v-checkbox
                  ></v-col>
                </v-row>
                <v-row v-if="configurationToAdd.main_family == 'commercial'">
                  <v-col cols="8">
                    <!-- COMMERCIAL -->
                    <v-autocomplete
                      :clearable="true"
                      v-model="configurationToAdd.commercials"
                      :items="[...computedCommercial]"
                      item-text="name"
                      item-value="id"
                      return-object
                      color="#704ad1"
                      item-color="#704ad1"
                      class="mb-1"
                      placeholder="Séléctionnez"
                      :persistent-placeholder="true"
                      label="Commercial *"
                      required
                      dense
                      :auto-focus="false"
                      no-data-text="Aucune société trouvée"
                      outlined
                      multiple
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      :loading="loadingFiliale"
                    >
                      <template v-slot:selection="{ index }">
                        <span
                          v-if="index === 0"
                          class="text-grey text-caption align-self-center"
                        >
                          +{{ configurationToAdd.commercials.length - 0 }}
                          Commerciaux
                        </span>
                      </template>
                    </v-autocomplete></v-col
                  >
                  <v-col cols="2">
                    <v-checkbox
                      label="Tous"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="
                        computedSelectAllComercials(
                          configurationToAdd.commercials
                        )
                      "
                      :true-value="true"
                      :false-value="false"
                      hide-details
                      @change="SelectAllCommercials($event)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="configurationToAdd.main_family == 'support'">
                  <v-col cols="8">
                    <!-- FAMILLE SUPPORT -->
                    <v-autocomplete
                      :clearable="true"
                      v-model="configurationToAdd.famillies"
                      :items="[...getListFamilleConfigFrias]"
                      item-text="familly"
                      item-value="id"
                      return-object
                      color="#704ad1"
                      item-color="#704ad1"
                      class="mb-1"
                      placeholder="Séléctionnez"
                      :persistent-placeholder="true"
                      label="Famille Support"
                      required
                      dense
                      :auto-focus="false"
                      no-data-text="Aucune société trouvée"
                      outlined
                      multiple
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      :loading="getLoadingFamilleConfig"
                    >
                      <template v-slot:selection="{ index }">
                        <span
                          v-if="index === 0"
                          class="text-grey text-caption align-self-center"
                        >
                          +{{ configurationToAdd.famillies.length - 0 }}
                          famille(s)
                        </span>
                      </template>
                    </v-autocomplete></v-col
                  >
                  <v-col cols="2">
                    <v-checkbox
                      label="Tous"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="
                        computedSelectAllFamilles(configurationToAdd.famillies)
                      "
                      :true-value="true"
                      :false-value="false"
                      hide-details
                      @change="SelectAllFamilles($event)"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <ModalCreateFamille></ModalCreateFamille>
                  </v-col>
                </v-row>
                <hr class="mb-4 mt-4" />
                <v-row>
                  <v-col cols="4"> Regroupement par :</v-col>
                  <v-col cols="4">
                    <v-checkbox
                      label="Par dossier"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="'dossier'"
                      :true-value="'dossier'"
                      :false-value="'groupe'"
                      hide-details
                      @change="changeState"
                      v-model="configurationToAdd.grouped"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="4">
                    <v-checkbox
                      label="Par groupe"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="'groupe'"
                      :true-value="'groupe'"
                      :false-value="'dossier'"
                      hide-details
                      @change="changeState"
                      v-model="configurationToAdd.grouped"
                    ></v-checkbox
                  ></v-col>
                </v-row>
                <v-row v-if="configurationToAdd.grouped == 'groupe'">
                  <v-col cols="6">
                    <!-- GROUPED BY  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToAdd.group_by"
                      :items="computedGetColumns"
                      item-text="value"
                      item-value="key"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Grouper par"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionnez"
                      no-data-text="Aucun élément trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6"
                    ><!-- FACTURE GROUPED BY  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToAdd.facture_group_by"
                      :items="computedGetColumns"
                      item-text="value"
                      item-value="key"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Facture grouper par"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionnez"
                      no-data-text="Aucun élément trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <hr class="mb-4 mt-4" />
                <v-row v-if="configurationToAdd.main_family == 'commercial'">
                  <v-col cols="3"> Appel à paiement : </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      label="Appel à paiement"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="'master'"
                      :true-value="'master'"
                      :false-value="null"
                      hide-details
                      v-model="configurationToAdd.payment_call"
                    ></v-checkbox
                  ></v-col>
                </v-row>
                <hr
                  class="mb-4 mt-4"
                  v-if="configurationToAdd.main_family == 'commercial'"
                />
                <v-row>
                  <v-col
                    :cols="configurationToAdd.acheteur_type == 'frais' ? 4 : 6"
                  >
                    <!-- COMPANY TO CONFIG  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToAdd.filiale_id"
                      :items="computedNotCommercial"
                      @change="setTvaList(configurationToAdd.filiale_id)"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Société à configurer"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner une Sociéte"
                      no-data-text="Aucun élément trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      :disabled="
                        configurationToAdd.main_family == 'commercial'
                          ? true
                          : false
                      "
                    >
                    </v-select>
                    <div
                      v-if="computedCheckSocieteSupport"
                      class="warning-week"
                    >
                      <font-awesome-icon class="info-icon" icon="info" />
                      cette sociéte n'est pas une société support, aucune
                      facture ne va pas être générer avec cette configuration
                    </div>
                  </v-col>
                  <v-col
                    :cols="configurationToAdd.acheteur_type == 'frais' ? 4 : 6"
                  >
                    <!-- TYPE  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToAdd.acheteur_type"
                      :items="acheteur_type_list"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Type"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun élément trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select
                  ></v-col>
                  <v-col
                    cols="4"
                    v-if="configurationToAdd.acheteur_type == 'frais'"
                  >
                    <!-- CLIENT  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToAdd.acheteur_id"
                      :items="computedNotCommercial"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Client"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun Client trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      @change="checkErrorsForAdd()"
                    >
                    </v-select
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      v-model="isDatePickerDebutVisibleConfig"
                      :close-on-content-click="false"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="configurationToAdd.date_debut"
                          label="Date début"
                          outlined
                          readonly
                          hide-details
                          clearable
                          @click:clear="checkErrorsForAdd"
                          v-on="on"
                          v-bind="attrs"
                          :persistent-placeholder="true"
                          placeholder="Sélectionner un date"
                          prepend-inner-icon="mdi-calendar"
                          color="#704ad1"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="configurationToAdd.date_debut"
                        @change="checkErrorsForAdd"
                        no-title
                        locale="fr"
                        color="#704ad1"
                        item-color="#704ad1"
                        format="DD-MM-YYYY"
                      >
                      </v-date-picker> </v-menu
                  ></v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="isDatePickerFinVisibleConfig"
                      :close-on-content-click="false"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="configurationToAdd.date_fin"
                          label="Date début"
                          outlined
                          readonly
                          hide-details
                          clearable
                          @click:clear="checkErrorsForAdd"
                          v-on="on"
                          v-bind="attrs"
                          :persistent-placeholder="true"
                          placeholder="Sélectionner un date"
                          prepend-inner-icon="mdi-calendar"
                          color="#704ad1"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="configurationToAdd.date_fin"
                        @change="checkErrorsForAdd"
                        no-title
                        locale="fr"
                        color="#704ad1"
                        item-color="#704ad1"
                        format="DD-MM-YYYY"
                      >
                      </v-date-picker> </v-menu
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <!-- TVA  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToAdd.tva"
                      :items="computedgetAllTvas"
                      item-text="valeur"
                      item-value="valeur"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="TVA %"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun element trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      @change="checkErrorsForAdd()"
                    >
                    </v-select
                  ></v-col>
                  <v-col cols="1">
                    <tva
                      :filiale_id="configurationToAdd.filiale_id"
                      @addTva="addTvaAction"
                      v-if="configurationToAdd.filiale_id"
                    ></tva
                  ></v-col>
                  <v-col cols="6">
                    <!-- MoODE CALCUL  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToAdd.coef_type"
                      :items="coef_type_reduction_options"
                      item-text="text"
                      item-value="value"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Mode de calcul"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun element trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      @change="checkErrorsForAdd()"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- DESCRIPTION  -->
                  <v-textarea
                    outlined
                    label="Description"
                    v-model="configurationToAdd.description"
                    :persistent-placeholder="true"
                    rows="3"
                    color="#704ad1"
                  ></v-textarea>
                </v-row>
                <v-row>
                  <!-- FACTURE DESCRIPTION  -->
                  <v-textarea
                    outlined
                    label="Facture description"
                    v-model="configurationToAdd.facture_description"
                    :persistent-placeholder="true"
                    rows="3"
                    color="#704ad1"
                  ></v-textarea>
                </v-row>
                <v-row>
                  <!-- REDUCTION  -->
                  <v-col cols="6">
                    <v-text-field
                      label="Réduction"
                      :persistent-placeholder="true"
                      v-model="configurationToAdd.reduction"
                      required
                      outlined
                      color="#704ad1"
                    ></v-text-field>
                    <!-- :rules="[v => !!v || 'Eéduction est obligatoire.']" -->
                  </v-col>
                  <v-col cols="6">
                    <!-- TYPE REDUCTION  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToAdd.reduction_type"
                      :items="coef_type_reduction_options"
                      item-text="text"
                      item-value="value"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Mode de calcul"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun element trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <!-- TOTAL  -->
                    <v-text-field
                      :label="
                        configurationToAdd.coef_type == 'pourcentage'
                          ? 'Total de Coefficient'
                          : 'Total du Montant'
                      "
                      :persistent-placeholder="true"
                      v-model="configurationToAdd.coef"
                      required
                      outlined
                      color="#704ad1"
                      :disabled="computedDisabledCoeffition"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="6">
                    <!-- OBJECT  -->
                    <v-text-field
                      :label="'Objet'"
                      :persistent-placeholder="true"
                      v-model="configurationToAdd.label"
                      required
                      outlined
                      color="#704ad1"
                      @input="checkErrorsForAdd"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row
                  v-for="(coeff, index) in configurationToAdd.coefs"
                  :key="index"
                >
                  <!-- TYPE PRODUCT  -->
                  <v-col :cols="3">
                    <v-select
                      v-model="coeff.type"
                      :items="typeProduct"
                      item-text="text"
                      item-value="value"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Type"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun element trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select
                  ></v-col>
                  <v-col cols="3">
                    <!-- TOTAL  -->
                    <v-text-field
                      :label="
                        configurationToAdd.coef_type == 'pourcentage'
                          ? 'Total de Coefficient'
                          : 'Total du Montant'
                      "
                      :persistent-placeholder="true"
                      v-model="coeff.coef"
                      required
                      outlined
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="index > 0 ? 5 : 6">
                    <!-- LABEL  -->
                    <v-textarea
                      outlined
                      label="Label"
                      v-model="coeff.label"
                      :persistent-placeholder="true"
                      rows="3"
                      color="#704ad1"
                    ></v-textarea
                  ></v-col>

                  <!-- DELETE TOTAL LINE  -->
                  <v-col cols="1"
                    ><v-btn
                      v-if="index > 0"
                      class="mx-2 button-add"
                      small
                      @click="deleteCoefficient(index, 'add')"
                    >
                      <v-icon dark>
                        mdi-delete
                      </v-icon>
                    </v-btn></v-col
                  >
                </v-row>
                <!-- ADD TOTAL LINE  -->
                <v-row
                  ><v-btn
                    class="mx-2 button-add"
                    small
                    @click="addNewcoeffition('add')"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn></v-row
                >
                <hr class="mt-8 mb-8" />
                <!-- FORMULE  -->
                <template
                  v-if="
                    configurationToAdd.coef_type === 'pourcentage' ||
                      configurationToAdd.coef_type === 'prix unitaire'
                  "
                >
                  <v-row>
                    Formules:
                  </v-row>
                  <v-row class="actions-msg-btn-modal">
                    <!-- ADD FORMULE  -->
                    <v-col cols="4">
                      <v-btn color="#704ad1" text @click="addNewFormule('add')">
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                        Ajouter une formule
                      </v-btn></v-col
                    >
                    <!-- SELECT CONCAT FORMULE  -->
                    <v-col
                      cols="4"
                      v-if="configurationToAdd.formules.length > 1"
                    >
                      <!-- FORMULE y=type  -->
                      <v-select
                        :clearable="true"
                        v-model="configurationToAdd.formule_types"
                        :items="formule_types_options"
                        item-text="text"
                        item-value="value"
                        dense
                        outlined
                        hide-details
                        class="mb-4"
                        label=""
                        item-color="#704ad1"
                        color="#704ad1"
                        :persistent-placeholder="true"
                        placeholder="Sélectionner"
                        no-data-text="Aucun element trouvé"
                        :menu-props="{
                          bottom: true,
                          offsetY: true
                        }"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <!-- COLONEE  -->
                      <v-select
                        :clearable="true"
                        :items="computedgetNumeriqCulomn"
                        item-text="value"
                        item-value="key"
                        dense
                        outlined
                        hide-details
                        class="mb-4"
                        label=""
                        item-color="#704ad1"
                        color="#704ad1"
                        :persistent-placeholder="true"
                        placeholder="Sélectionner"
                        no-data-text="Aucun element trouvé"
                        :menu-props="{
                          bottom: true,
                          offsetY: true
                        }"
                        @input="CopyFields($event)"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="isTextCopied"> Text copier </v-row>
                  <v-row
                    v-for="(formu, index) in configurationToAdd.formules"
                    :key="index + 'Formule'"
                  >
                    <v-col cols="10">
                      <v-text-field
                        :label="'Formule'"
                        :persistent-placeholder="true"
                        v-model="formu.formule"
                        required
                        outlined
                        color="#704ad1"
                        @keyup="checkFormuleConfigFraisDynamic('add', index)"
                      ></v-text-field>
                      <em class="error-message ">{{ formu.error }} </em>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        class="mx-2 button-add"
                        small
                        @click="deleteFormule(index, 'add')"
                        title="Supprimer une formule"
                      >
                        <v-icon dark>
                          mdi-delete
                        </v-icon>
                      </v-btn></v-col
                    >
                  </v-row>
                  <v-row v-if="configurationToAdd.formules.length">
                    <v-col cols="4"> Formules Finale: </v-col>
                    <v-col cols="8">
                      <em> {{ computedFormule(configurationToAdd) }}</em>
                    </v-col>
                  </v-row></template
                >
              </v-col>
              <v-col lg="5" md="12" sm="12" class="pl-4">
                <v-row>
                  <v-col cols="12"> Condition de configuration </v-col>
                </v-row>
                <v-row class="actions-msg-btn-modal">
                  <!-- ADD CONDITION  -->
                  <v-col cols="6"
                    ><v-btn
                      color="#704ad1"
                      text
                      @click="addNewCondition('add')"
                    >
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                      Ajouter une condition
                    </v-btn></v-col
                  >
                  <v-col
                    cols="6"
                    v-if="configurationToAdd.conditions.length > 1"
                  >
                    <!-- RELATION CONDITION  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToAdd.condition_types"
                      :items="and_or_options"
                      item-text="value"
                      item-value="key"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label=""
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun element trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(condition, index) in configurationToAdd.conditions"
                  :key="index + 'condition'"
                >
                  <p class="m-0" hidden>
                    {{ (condition.index = index) }}
                  </p>
                  <v-col cols="4">
                    <!-- COLONNE -->
                    <v-autocomplete
                      :title="conputedSelectedColumn(condition.column)"
                      :clearable="true"
                      v-model="condition.column"
                      :items="[...computedGetColumns]"
                      item-text="value"
                      item-value="key"
                      color="#704ad1"
                      item-color="#704ad1"
                      class="mb-1"
                      placeholder="Séléctionnez"
                      :persistent-placeholder="true"
                      label="Colonne"
                      required
                      dense
                      :auto-focus="false"
                      no-data-text="Aucune colonne trouvée"
                      outlined
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      @changeValue="
                        condition.value = checkColomnConditionConfig(
                          condition.column
                        )
                          ? null
                          : condition.value
                      "
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    :cols="
                      condition.operation != 'ISNULL' &&
                      condition.operation != 'ISNOTNULL'
                        ? 3
                        : 6
                    "
                  >
                    <!-- OPERATION  -->
                    <v-select
                      :clearable="true"
                      v-model="condition.operation"
                      :items="operation_options"
                      item-text="text"
                      item-value="value"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Opération"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun element trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select>
                  </v-col>
                  <v-col
                    cols="3"
                    v-if="
                      condition.operation != 'ISNULL' &&
                        condition.operation != 'ISNOTNULL'
                    "
                  >
                    <!-- VALUE  -->
                    <!-- COLONNE -->
                    <v-autocomplete
                      v-if="checkColomnConditionConfig(condition.column)"
                      :clearable="true"
                      v-model="condition.value"
                      :items="
                        condition.column == 'organisme'
                          ? getListOrganismeObligee
                          : getProFilials
                      "
                      item-text="name"
                      item-value="name"
                      color="#704ad1"
                      item-color="#704ad1"
                      class="mb-1"
                      placeholder="Séléctionnez"
                      :persistent-placeholder="true"
                      label="Valeur"
                      required
                      dense
                      :auto-focus="false"
                      no-data-text="Aucun Valeur trouvé"
                      outlined
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-autocomplete>
                    <v-text-field
                      v-else
                      :label="'Valeur'"
                      :persistent-placeholder="true"
                      v-model="condition.value"
                      required
                      outlined
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                  <!-- DELETE CONDITION  -->
                  <v-col cols="2">
                    <v-btn
                      class="mx-2 button-add"
                      small
                      @click="deleteCondition(index, 'add')"
                    >
                      <v-icon dark>
                        mdi-delete
                      </v-icon>
                    </v-btn></v-col
                  >
                </v-row>
                <template
                  v-if="
                    configurationToAdd.filiale_id != null &&
                      configurationToAdd.date_debut != null
                  "
                >
                  <v-row><b>Specifics:</b> </v-row>
                  <v-row>
                    <v-col cols="2"
                      ><b>
                        <v-switch
                          class="check-style-custom-obligee-mail"
                          v-model="checkAll"
                          :value="true"
                          :unchecked-value="false"
                          color="#704ad1"
                          @change="changeAllCheckColumns"
                        >
                          <template v-slot:label>
                            <span class="title-check-commercial-mail">
                              Tous</span
                            >
                          </template>
                        </v-switch></b
                      >
                    </v-col>
                    <v-col cols="2"
                      ><b>
                        <v-switch
                          class="check-style-custom-obligee-mail"
                          v-model="specific_master"
                          :value="true"
                          :unchecked-value="false"
                          color="#704ad1"
                          @change="changeMastersFormmat('add')"
                        >
                          <template v-slot:label>
                            <span class="title-check-commercial-mail">
                              Master</span
                            >
                          </template>
                        </v-switch></b
                      >
                    </v-col>
                    <v-col cols="1"></v-col>
                    <v-col cols="7"
                      ><v-text-field
                        label="Rechercher"
                        v-model="search"
                        outlined
                        clearable
                        hide-details
                        :persistent-placeholder="true"
                        :placeholder="'Rechercher'"
                        color="#704ad1"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="scroll-specifics-params">
                    <v-col
                      cols="6"
                      v-for="(option, index) in computedSearchListSpecifics"
                      :key="'option-check-1' + index"
                    >
                      <v-checkbox
                        :key="'check-1' + index"
                        :label="option.name"
                        class="checked-tva margin-none"
                        color="#704ad1"
                        :value="option.option"
                        v-model="configurationToAdd.specifics"
                        @change="checkErrorsForAdd(option)"
                      >
                      </v-checkbox>

                      <template v-if="specific_master && option.master">
                        <v-checkbox
                          v-for="(master, indexM) in option.societe_master"
                          :key="'option-check-2' + indexM"
                          :label="master.name"
                          class="checked-tva margin-none ml-4"
                          color="#704ad1"
                          :value="master.option"
                          v-model="configurationToAdd.specifics"
                          @change="checkErrorsForAdd"
                        ></v-checkbox>
                      </template>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleSubmitConfiguration"
            :loading="getIsLoadingConfigFraisTh"
            :disabled="getIsLoadingConfigFraisTh"
            :class="{ loader: getIsLoadingConfigFraisTh }"
          >
            Valider
          </v-btn>
          <v-btn text @click.prevent.stop="handleModalConfig('add', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END MODAL CREATION CONFIGURATION  -->

    <!-- BLOCK FILTER  -->
    <div class="cadre-block-filter">
      <div class="right-block">
        <!-- DATE DEBUT -->
        <div class="date-input-vuetify mr-1">
          <v-menu
            v-model="isDatePickerDebutVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateStartforSearch"
                label="Date début"
                outlined
                readonly
                hide-details
                clearable
                @click:clear="searchFilter"
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                color="#704ad1"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="dateStartforSearch"
              @change="searchFilter"
              no-title
              locale="fr"
              color="#704ad1"
              item-color="#704ad1"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <!-- DATE FIN -->
        <div class="date-input-vuetify mr-1">
          <v-menu
            v-model="isDatePickerFinVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateEndForSearch"
                label="Date fin"
                outlined
                readonly
                @click:clear="searchFilter"
                hide-details
                v-on="on"
                v-bind="attrs"
                clearable
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                color="#704ad1"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateEndForSearch"
              @input="searchFilter"
              no-title
              locale="fr"
              color="#704ad1"
              item-color="#704ad1"
            ></v-date-picker>
          </v-menu>
        </div>
        <!-- DATE ACTIVE -->
        <div class="date-input-vuetify mr-1">
          <v-menu
            v-model="isDatePickerActiveVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="active_date"
                label="Date active"
                outlined
                readonly
                @click:clear="handelFilter"
                hide-details
                v-on="on"
                v-bind="attrs"
                clearable
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                color="#704ad1"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="active_date"
              @input="handelFilter"
              no-title
              locale="fr"
              color="#704ad1"
              item-color="#704ad1"
            ></v-date-picker>
          </v-menu>
        </div>
        <!-- LOADER  -->
        <v-progress-circular
          class="ml-2"
          v-if="getIsLoadingConfigFraisTh"
          indeterminate
          color="#704ad1"
        ></v-progress-circular>
      </div>
      <div class="left-block">
        <!-- SEARCH -->
        <div class="serach-input mr-2">
          <search
            :value="nom_societe"
            @changeSearchValue="searchFilter($event, 'nom_societe')"
            hiddenContent="true"
          />
        </div>

        <!-- TOTTIP FILTER  -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on, attrs }">
            <div
              size="sm"
              v-on="on"
              v-bind="attrs"
              @click="menu = true"
              class="block-icon mr-2 button-height-unset"
            >
              <IconFiltre></IconFiltre>
            </div>
          </template>
          <span>Choisir un Filtre</span>
        </v-tooltip>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="350"
          origin="top right"
          content-class="popover-menu"
        >
          <v-card>
            <v-divider class="line-hr-filter"></v-divider>
            <v-card-text class="body-card filter-content-popup">
              <v-form>
                <!-- SOCIETE -->
                <v-autocomplete
                  :clearable="true"
                  return-object
                  v-model="isLocalStoragevendeurs"
                  :items="[...computedNotCommercial]"
                  item-text="name"
                  item-value="id"
                  color="#704ad1"
                  item-color="#704ad1"
                  class="mb-1"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Société"
                  required
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStoragevendeurs.length - 0 }} société(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- FAMILLE -->
                <v-select
                  :clearable="true"
                  v-model="isLocalStoragfamille"
                  :items="[{ value: null, text: '' }, ...computedListFamille]"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Famille"
                  item-color="#704ad1"
                  color="#704ad1"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  @change="
                    isLocalStoragfamille == 'commercial'
                      ? null
                      : (isLocalStoragecommercials = [])
                  "
                >
                </v-select>
                <!-- COMMERCIAL -->
                <v-autocomplete
                  return-object
                  v-if="isLocalStoragfamille == 'commercial'"
                  :clearable="true"
                  v-model="isLocalStoragecommercials"
                  :items="[...computedCommercial]"
                  item-text="name"
                  item-value="id"
                  color="#704ad1"
                  item-color="#704ad1"
                  class="mb-1"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Commercial"
                  required
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStoragecommercials.length - 0 }} Commerciaux
                    </span>
                  </template>
                </v-autocomplete>
                <!-- ACTIVE -->
                <v-select
                  :clearable="true"
                  v-model="isLocalStorageactive"
                  :items="[
                    { value: null, text: 'Tous' },
                    { value: 'non', text: 'Non' },
                    { value: 'oui', text: 'Oui' }
                  ]"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Active"
                  item-color="#704ad1"
                  color="#704ad1"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select> </v-form
            ></v-card-text>
            <v-card-actions class="popover-action-filter">
              <v-btn
                class="ma-2 capitalize-text"
                outlined
                color="#5E35B1"
                @click="validerPopover"
              >
                Appliquer
              </v-btn>
              <v-btn
                class="ma-2 ml-2 capitalize-text"
                text
                @click="closePopover"
              >
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions> </v-card
        ></v-menu>
        <!-- ADD NEW CONFIGURATION  -->
        <v-tooltip bottom color="#311B92" v-if="checkPermission('GLCTHAC')">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="block-icon-setting text-center"
              v-on="on"
              v-bind="attrs"
              @click.prevent.stop="handleModalConfig('add', 'show')"
            >
              <font-awesome-icon icon="plus-circle" />
            </div>
          </template>
          <span>Ajouter une configuration</span>
        </v-tooltip>
      </div>
    </div>
    <!-- END BLOCK FILTER  -->

    <div class="content-cadre-table-simulation">
      <!-- BLOCK SELECTED FILTER  -->
      <div class="">
        <div v-if="returnTableChips" class="block-filter-style mb-2">
          <span class="phrase-style">Les filtres choisie sont : </span>
          <span class="item-filter-style" v-if="dateStartforSearch !== null"
            >Date début : {{ dateStartforSearch }}</span
          >
          <span class="item-filter-style" v-if="dateEndForSearch !== null"
            >Date fin : {{ dateEndForSearch }}</span
          >
          <span class="item-filter-style" v-if="active_date !== null"
            >Date active : {{ active_date }}</span
          >
          <span class="item-filter-style" v-if="famille !== null"
            >Famille : {{ famille }}</span
          >
          <span class="item-filter-style" v-if="active !== null"
            >Active : {{ active }}</span
          >
          <span
            v-if="nom_societe !== null && nom_societe !== ''"
            class="item-filter-style"
            >Recherche : {{ nom_societe }}</span
          >
        </div>
      </div>
      <v-row class="filter-Block-icon" v-if="returnTableFiltre">
        <v-col
        :cols="computedLengthFilter"
          v-if="vendeurs && vendeurs.length"
        >
          <span class="title-block-chip-filter">Société : </span>
          <v-sheet elevation="1" class="py-0 px-1 mr-3">
            <v-chip-group mandatory>
              <v-chip
                :key="vd.id"
                v-for="vd in vendeurs"
                close
                @click:close="remove(vd, 'vendeurs', 'searchFilter')"
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ vd.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <v-col
          :cols="computedLengthFilter"
          v-if="commercials && commercials.length"
        >
          <span class="title-block-chip-filter">Commercial : </span>
          <v-sheet elevation="1" class="py-0 px-1 mr-3">
            <v-chip-group mandatory>
              <v-chip
                :key="vd.id"
                v-for="vd in commercials"
                close
                @click:close="remove(vd, 'commercials', 'searchFilter')"
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ vd.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
      </v-row>
      <!--END  BLOCK SELECTED FILTER  -->

      <!-- TABLE  CONFIGURATION -->
      <div
        class="table-config-fac"
        :class="{
          'padding-table': returnTableFiltre
        }"
      >
        <v-data-table
          v-model="selectedTable"
          :headers="fields"
          :items="getAllConfigurations"
          @item-expand="getAllConfigurations"
          :items-per-page="perPage"
          :class="{
            'height-filter-chips-table': returnTableChips && !returnTableFiltre,
            'height-filter-table': returnTableFiltre && !returnTableChips,
            'height-filter-tous': returnTableChips && returnTableFiltre
          }"
          class="table-simulation-global"
          hide-default-footer
          :expanded.sync="expanded"
          :calculate-widths="false"
          :fixed-header="true"
          hide-action
          :no-data-text="
            getIsLoadingConfigFraisTh
              ? 'Chargement...'
              : `Il n'y a aucun enregistrement à afficher`
          "
        >
          <!--ROWS DETAILS-->
          <template v-slot:[`item.details`]="{ item }">
            <td class="custom-cell sticky-header-1">
              <button class="btn btn-anomalie" @click="getDataForRow(item)">
                <font-awesome-icon
                  class="icon-plus"
                  icon="plus-square"
                  color="#704ad1"
                  title="Afficher dossiers"
                  v-if="item.detailsShowing == false"
                />
                <font-awesome-icon
                  class="icon-plus"
                  icon="minus-square"
                  color="#704ad1"
                  title="Masquer dossiers"
                  v-if="item.detailsShowing == true"
                />
              </button>
            </td>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <td class="custom-cell sticky-header-2">
              {{ item.name }}
            </td>
          </template>

          <template #expanded-item="{ headers,item }">
            <td
              v-if="item.detailsShowing == true"
              class="td-expanded"
              :colspan="headers.length"
            >
              <table :items="item.projects" class="table-expanded">
                <tbody>
                  <tr
                    v-for="(data, index) in item.configs"
                    :key="item.name + 'config' + index"
                  >
                    <td
                      class="td-expanded sticky-header-1"
                      style="width:40px  !important ; min-width:40px !important"
                    >
                      <span></span>
                    </td>
                    <td class="td-expanded sticky-header-2">
                      <div
                        v-if="
                          data.main_family && data.main_family != 'commercial'
                        "
                      >
                        {{ data.filiale_name }}
                      </div>
                      <div v-else class="text-left">
                        <span
                          v-for="i in data.commercials"
                          :key="'commercial' + i.id"
                          >- {{ i.commercial }}</span
                        >
                      </div>
                    </td>
                    <td class="td-expanded">
                      {{ data.description ? data.description : '-' }}
                    </td>
                    <td class="td-expanded">
                      {{ data.coef }}
                    </td>
                    <td class="td-expanded">
                      {{ data.label }}
                    </td>
                    <td class="td-expanded">
                      {{ data.date_debut }}
                    </td>
                    <td class="td-expanded">
                      {{ data.date_fin ? data.date_fin : '-' }}
                    </td>
                    <td class="td-expanded">
                      {{ data.main_family ? data.main_family : '-' }}
                    </td>
                    <td class="td-expanded">
                      {{ data.user ? data.user : '-' }}
                    </td>
                    <td class="td-expanded td-col-actions">
                      <div class="d-flex actions-style-table">
                        <div
                          v-if="checkPermission('GLCTHDC')"
                          class="bloc-icon-gestion mr-2"
                          @click="duplicateConfiguration(data)"
                          title="Dupliquer la configuration"
                        >
                          <font-awesome-icon icon="copy" />
                        </div>
                        <div
                          v-if="checkPermission('GLCTHMC')"
                          class="bloc-icon-gestion mr-2"
                          @click="handleModalConfig('update', 'show', data)"
                          title="Modifier la configuration"
                        >
                          <font-awesome-icon icon="pencil-alt" />
                        </div>
                        <div
                          v-if="checkPermission('GLCTHVC')"
                          class="bloc-icon-gestion mr-2"
                          @click="BlockUnblockConfigclick(data)"
                        >
                          <font-awesome-icon
                            v-if="data.blocked == true"
                            title="Déblocker configuration"
                            icon="eye-slash"
                          />

                          <font-awesome-icon
                            v-if="data.blocked == false"
                            title="blocker configuration"
                            icon="eye"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </template>
        </v-data-table>
        <div class="footer-style-table">
          <div class="text-center pagination-table">
            <v-pagination
              v-model="page"
              :length="computedTotal"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              @input="pagination"
            ></v-pagination>
          </div>
          <div class="select-input-vuetify">
            <v-select
              v-model="perPage"
              :items="perPageList"
              @change="changePerPage"
              label="Eléments par page"
              outlined
              dense
              hide-details
              no-data-text="Aucun élément trouvé"
              :menu-props="{
                left: true,
                offsetX: true
              }"
              color="#704ad1"
              item-color="#704ad1"
            ></v-select>
          </div>
        </div>
      </div>
      <!-- END TABLE CONFIGURATION -->
    </div>

    <!-- MODAL UPDATE CONFIGURATION  -->

    <v-dialog
      v-model="updateConfigurationModal"
      persistent
      max-width="98%"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add custom-top-modal"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            Modifier la configuration de la sociéte
            {{
              configurationToUpdate && configurationToUpdate.filiale_name
                ? configurationToUpdate.filiale_name
                : '_'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalConfig('update', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <!-- <div class="messageError" v-if="errorConfig">
                <div class="error">
                  {{ errorConfig }}
                </div>
              </div> -->
          <div v-if="getConfigurationError" class="message-error-modal">
            <ul v-if="Array.isArray(getConfigurationError)" class="mb-0">
              <li v-for="(e, index) in getConfigurationError" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>
              {{ getConfigurationError }}
            </div>
          </div>
          <div v-if="getErrorForSpecific" class="message-error-modal mt-2 ">
            <v-tooltip bottom color="#311B92">
              <template v-slot:activator="{ on, attrs }">
                {{ getErrorForSpecific.error }}
                <div size="sm" v-on="on" v-bind="attrs">
                  <font-awesome-icon icon="info" />
                </div>
              </template>
              <span>
                <ul class="mb-0" style="list-style-type: none;">
                  <li
                    v-for="(error, index) in getErrorForSpecific.acheteurs"
                    :key="index"
                  >
                    {{ error }}
                  </li>
                </ul>
              </span>
            </v-tooltip>
          </div>

          <form class="form-add">
            <v-row class="row" v-if="configurationToUpdate != null">
              <v-col lg="7" md="12" sm="12" class="pr-4">
                <v-row>
                  <v-col cols="2"> Famille:</v-col>
                  <b-col sm="2" md="2">
                    <v-checkbox
                      label="Support"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="'support'"
                      :true-value="'support'"
                      :false-value="null"
                      hide-details
                      @change="changeInitialFamily"
                      v-model="configurationToUpdate.main_family"
                    ></v-checkbox>
                  </b-col>
                  <b-col sm="2" md="2">
                    <v-checkbox
                      label="Master"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="'master'"
                      :true-value="'master'"
                      :false-value="null"
                      hide-details
                      @change="changeInitialFamily"
                      v-model="configurationToUpdate.main_family"
                    ></v-checkbox>
                  </b-col>
                  <b-col sm="2" md="2">
                    <v-checkbox
                      @change="
                        changeInitialFamily();
                        configurationToUpdate.filiale_id = 291;
                      "
                      label="Commercial"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="'commercial'"
                      :true-value="'commercial'"
                      :false-value="null"
                      hide-details
                      v-model="configurationToUpdate.main_family"
                    ></v-checkbox>
                  </b-col>
                </v-row>
                <v-row v-if="configurationToUpdate.main_family == 'commercial'">
                  <v-col cols="8">
                    <!-- COMMERCIAL -->
                    <v-autocomplete
                      :clearable="true"
                      v-model="configurationToUpdate.commercials"
                      :items="[...computedCommercial]"
                      item-text="name"
                      item-value="id"
                      return-object
                      color="#704ad1"
                      item-color="#704ad1"
                      class="mb-1"
                      placeholder="Séléctionnez"
                      :persistent-placeholder="true"
                      label="Commercial *"
                      required
                      dense
                      :auto-focus="false"
                      no-data-text="Aucune société trouvée"
                      outlined
                      multiple
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      :loading="loadingFiliale"
                    >
                      <template v-slot:selection="{ index }">
                        <span
                          v-if="index === 0"
                          class="text-grey text-caption align-self-center"
                        >
                          +{{ configurationToUpdate.commercials.length - 0 }}
                          Commerciaux
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <v-checkbox
                      label="Tous"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="
                        computedSelectAllComercials(
                          configurationToUpdate.commercials
                        )
                      "
                      :true-value="true"
                      :false-value="false"
                      hide-details
                      @change="SelectAllUpdateCommercials($event)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="configurationToUpdate.main_family == 'support'">
                  <v-col cols="8">
                    <!-- FAMILLE SUPPORT -->
                    <v-autocomplete
                      :clearable="true"
                      v-model="configurationToUpdate.famillies"
                      :items="[...getListFamilleConfigFrias]"
                      item-text="familly"
                      item-value="id"
                      return-object
                      color="#704ad1"
                      item-color="#704ad1"
                      class="mb-1"
                      placeholder="Séléctionnez"
                      :persistent-placeholder="true"
                      label="Famille Support"
                      required
                      dense
                      :auto-focus="false"
                      no-data-text="Aucune société trouvée"
                      outlined
                      multiple
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      :loading="getLoadingFamilleConfig"
                    >
                      <template v-slot:selection="{ index }">
                        <span
                          v-if="index === 0"
                          class="text-grey text-caption align-self-center"
                        >
                          +{{ configurationToUpdate.famillies.length - 0 }}
                          famille(s)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <v-checkbox
                      label="Tous"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="
                        computedSelectAllFamilles(
                          configurationToUpdate.famillies
                        )
                      "
                      :true-value="true"
                      :false-value="false"
                      hide-details
                      @change="SelectAllFamillesUpdate($event)"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <ModalCreateFamille></ModalCreateFamille>
                  </v-col>
                </v-row>
                <hr class="mb-4 mt-4" />
                <v-row>
                  <v-col cols="4"> Regroupement par :</v-col>
                  <v-col cols="4">
                    <v-checkbox
                      label="Par dossier"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="'dossier'"
                      :true-value="'dossier'"
                      :false-value="'groupe'"
                      hide-details
                      @change="stateInitial"
                      v-model="configurationToUpdate.grouped"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      label="Par groupe"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="'groupe'"
                      :true-value="'groupe'"
                      :false-value="'dossier'"
                      hide-details
                      @change="stateInitial"
                      v-model="configurationToUpdate.grouped"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="configurationToUpdate.grouped == 'groupe'">
                  <v-col cols="6">
                    <!-- GROUPED BY  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToUpdate.group_by"
                      :items="computedGetColumns"
                      item-text="value"
                      item-value="key"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Grouper par"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionnez"
                      no-data-text="Aucun élément trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6"
                    ><!-- FACTURE GROUPED BY  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToUpdate.facture_group_by"
                      :items="computedGetColumns"
                      item-text="value"
                      item-value="key"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Facture grouper par"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionnez"
                      no-data-text="Aucun élément trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <hr class="mb-4 mt-4" />
                <v-row
                  class="ModelCol mr-3"
                  v-if="configurationToUpdate.main_family == 'commercial'"
                >
                  <v-col cols="3"> Appel à paiement : </v-col>

                  <b-col cols="4">
                    <v-checkbox
                      label="Appel à paiement"
                      class="checked-tva margin-none"
                      color="#704ad1"
                      :value="'master'"
                      :true-value="'master'"
                      :false-value="null"
                      hide-details
                      v-model="configurationToUpdate.payment_call"
                    ></v-checkbox>
                  </b-col>
                </v-row>
                <hr
                  class="mb-4 mt-4"
                  v-if="configurationToUpdate.main_family == 'commercial'"
                />
                <v-row>
                  <v-col
                    :cols="
                      configurationToUpdate.acheteur_type == 'frais' ? 4 : 6
                    "
                  >
                    <!-- COMPANY TO CONFIG  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToUpdate.filiale_id"
                      :items="computedNotCommercial"
                      @change="
                        setTvaList(configurationToUpdate.filiale_id);
                        checkErrorsForUpdate();
                      "
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Société à configurer"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner une Sociéte"
                      no-data-text="Aucun élément trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      :disabled="
                        configurationToUpdate.main_family == 'commercial'
                          ? true
                          : false
                      "
                    >
                    </v-select>
                    <div
                      v-if="computedCheckSocieteSupport"
                      class="warning-week"
                    >
                      <font-awesome-icon class="info-icon" icon="info" />
                      cette sociéte n'est pas une société support, aucune
                      facture ne va pas être générer avec cette configuration
                    </div>
                  </v-col>
                  <v-col
                    :cols="
                      configurationToUpdate.acheteur_type == 'frais' ? 4 : 6
                    "
                  >
                    <!-- TYPE  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToUpdate.acheteur_type"
                      :items="acheteur_type_list"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Type"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun élément trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select
                  ></v-col>
                  <v-col
                    cols="4"
                    v-if="configurationToUpdate.acheteur_type == 'frais'"
                  >
                    <!-- CLIENT  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToUpdate.acheteur_id"
                      :items="computedNotCommercial"
                      item-text="name"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Client"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun Client trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      @change="checkErrorsForUpdate()"
                    >
                    </v-select
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      v-model="isDatePickerDebutVisibleUpdateConfig"
                      :close-on-content-click="false"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="configurationToUpdate.date_debut"
                          label="Date début"
                          outlined
                          readonly
                          hide-details
                          clearable
                          v-on="on"
                          v-bind="attrs"
                          :persistent-placeholder="true"
                          placeholder="Sélectionner un date"
                          prepend-inner-icon="mdi-calendar"
                          color="#704ad1"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="configurationToUpdate.date_debut"
                        no-title
                        locale="fr"
                        color="#704ad1"
                        item-color="#704ad1"
                        format="DD-MM-YYYY"
                      >
                      </v-date-picker> </v-menu
                  ></v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="isDatePickerFinVisibleUpdateConfig"
                      :close-on-content-click="false"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="configurationToUpdate.date_fin"
                          label="Date début"
                          outlined
                          readonly
                          hide-details
                          clearable
                          v-on="on"
                          v-bind="attrs"
                          :persistent-placeholder="true"
                          placeholder="Sélectionner un date"
                          prepend-inner-icon="mdi-calendar"
                          color="#704ad1"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="configurationToUpdate.date_fin"
                        no-title
                        locale="fr"
                        color="#704ad1"
                        item-color="#704ad1"
                        format="DD-MM-YYYY"
                      >
                      </v-date-picker> </v-menu
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <!-- TVA  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToUpdate.tva"
                      :items="computedgetAllTvas"
                      item-text="valeur"
                      item-value="valeur"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="TVA %"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun element trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select
                  ></v-col>
                  <v-col cols="1">
                    <tva
                      :filiale_id="configurationToUpdate.filiale_id"
                      @addTva="addTvaAction"
                      v-if="configurationToUpdate.filiale_id"
                    ></tva
                  ></v-col>
                  <v-col
                    cols="6"
                    v-if="
                      configurationToUpdate && configurationToUpdate.coef_type
                    "
                  >
                    <!-- MoODE CALCUL  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToUpdate.coef_type"
                      :items="coef_type_reduction_options"
                      item-text="text"
                      item-value="value"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Mode de calcul"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun element trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- DESCRIPTION  -->
                  <v-textarea
                    outlined
                    label="Description"
                    v-model="configurationToUpdate.description"
                    :persistent-placeholder="true"
                    rows="3"
                    color="#704ad1"
                  ></v-textarea>
                </v-row>
                <v-row>
                  <!-- FACTURE DESCRIPTION  -->
                  <v-textarea
                    outlined
                    label="Facture description"
                    v-model="configurationToUpdate.facture_description"
                    :persistent-placeholder="true"
                    rows="3"
                    color="#704ad1"
                  ></v-textarea>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Réduction"
                      :persistent-placeholder="true"
                      v-model="configurationToUpdate.reduction"
                      required
                      outlined
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <!-- TYPE REDUCTION  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToUpdate.reduction_type"
                      :items="coef_type_reduction_options"
                      item-text="text"
                      item-value="value"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Mode de calcul"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun element trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="6"
                    v-if="
                      configurationToUpdate && configurationToUpdate.coef_type
                    "
                  >
                    <!-- TOTAL  -->
                    <v-text-field
                      :label="
                        configurationToUpdate.coef_type == 'pourcentage'
                          ? 'Total de Coefficient'
                          : 'Total du Montant'
                      "
                      :persistent-placeholder="true"
                      v-model="configurationToUpdate.coef"
                      required
                      outlined
                      color="#704ad1"
                      :disabled="computedDidabledCoeffToUpdate"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="6">
                    <!-- OBJECT  -->
                    <v-text-field
                      :label="'Objet'"
                      :persistent-placeholder="true"
                      v-model="configurationToUpdate.label"
                      required
                      outlined
                      color="#704ad1"
                      @input="checkErrorsForUpdate"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row
                  v-for="(coeff, index) in configurationToUpdate.coefs"
                  :key="index"
                >
                  <!-- TYPE PRODUCT  -->
                  <v-col :cols="3">
                    <v-select
                      v-model="coeff.type"
                      :items="typeProduct"
                      item-text="text"
                      item-value="value"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Type"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun element trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select
                  ></v-col>

                  <v-col
                    cols="3"
                    v-if="
                      configurationToUpdate && configurationToUpdate.coef_type
                    "
                  >
                    <!-- TOTAL  -->
                    <v-text-field
                      :label="
                        configurationToUpdate.coef_type == 'pourcentage'
                          ? 'Coefficient'
                          : 'Montant'
                      "
                      :persistent-placeholder="true"
                      v-model="coeff.coef"
                      required
                      outlined
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="index > 0 ? 5 : 6">
                    <!-- LABEL  -->
                    <v-textarea
                      outlined
                      label="Label"
                      v-model="coeff.label"
                      :persistent-placeholder="true"
                      rows="3"
                      color="#704ad1"
                    ></v-textarea
                  ></v-col>
                  <!-- DELETE TOTAL LINE  -->
                  <v-col cols="1"
                    ><v-btn
                      v-if="index > 0"
                      class="mx-2 button-add"
                      small
                      @click="deleteCoefficient(index, 'update')"
                    >
                      <v-icon dark>
                        mdi-delete
                      </v-icon>
                    </v-btn></v-col
                  >
                </v-row>
                <!-- ADD TOTAL LINE  -->
                <v-row
                  ><v-btn
                    class="mx-2 button-add"
                    small
                    @click="addNewcoeffition('update')"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn></v-row
                >
                <hr class="mt-8 mb-8" />
                <template
                  v-if="
                    configurationToUpdate.coef_type === 'pourcentage' ||
                      configurationToUpdate.coef_type === 'prix unitaire'
                  "
                >
                  <v-row>
                    Formules:
                  </v-row>
                  <v-row class="actions-msg-btn-modal">
                    <!-- ADD FORMULE  -->
                    <v-col cols="4">
                      <v-btn color="#704ad1" text @click="addNewFormule">
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                        Ajouter une formule
                      </v-btn></v-col
                    >
                    <!-- SELECT CONCAT FORMULE  -->
                    <v-col
                      cols="4"
                      v-if="configurationToUpdate.formules.length > 1"
                    >
                      <!-- FORMULE y=type  -->
                      <v-select
                        :clearable="true"
                        v-model="configurationToUpdate.formule_types"
                        :items="formule_types_options"
                        item-text="text"
                        item-value="value"
                        dense
                        outlined
                        hide-details
                        class="mb-4"
                        label=""
                        item-color="#704ad1"
                        color="#704ad1"
                        :persistent-placeholder="true"
                        placeholder="Sélectionner"
                        no-data-text="Aucun element trouvé"
                        :menu-props="{
                          bottom: true,
                          offsetY: true
                        }"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <!-- COLONEE  -->
                      <v-select
                        :clearable="true"
                        :items="computedgetNumeriqCulomn"
                        item-text="value"
                        item-value="key"
                        dense
                        outlined
                        hide-details
                        class="mb-4"
                        label=""
                        item-color="#704ad1"
                        color="#704ad1"
                        :persistent-placeholder="true"
                        placeholder="Sélectionner"
                        no-data-text="Aucun element trouvé"
                        :menu-props="{
                          bottom: true,
                          offsetY: true
                        }"
                        @input="CopyFields($event)"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="isTextCopied"> Text copier </v-row>
                  <v-row
                    v-for="(formu, index) in configurationToUpdate.formules"
                    :key="index + 'Formule'"
                  >
                    <v-col cols="10">
                      <v-text-field
                        :label="'Formule'"
                        :persistent-placeholder="true"
                        v-model="formu.formule"
                        required
                        outlined
                        color="#704ad1"
                        @keyup="checkFormuleConfigFraisDynamic('update', index)"
                      ></v-text-field>
                      <em class="error-message ">{{ formu.error }} </em>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        class="mx-2 button-add"
                        small
                        @click="deleteFormule(index, 'update')"
                        title="Supprimer une formule"
                      >
                        <v-icon dark>
                          mdi-delete
                        </v-icon>
                      </v-btn></v-col
                    >
                  </v-row>
                  <v-row v-if="configurationToUpdate.formules.length">
                    <v-col cols="4"> Formules Finale: </v-col>
                    <v-col cols="8">
                      <em> {{ computedFormule(configurationToUpdate) }}</em>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
              <v-col lg="5" md="12" sm="12" class="pl-4">
                <v-row>
                  <v-col cols="12"> Condition de configuration </v-col>
                </v-row>
                <v-row class="actions-msg-btn-modal">
                  <!-- ADD CONDITION  -->
                  <v-col cols="6"
                    ><v-btn color="#704ad1" text @click="addNewCondition">
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                      Ajouter une condition
                    </v-btn></v-col
                  >
                  <v-col
                    cols="6"
                    v-if="
                      configurationToUpdate &&
                        configurationToUpdate.conditions &&
                        configurationToUpdate.conditions.length > 1
                    "
                  >
                    <!-- RELATION CONDITION  -->
                    <v-select
                      :clearable="true"
                      v-model="configurationToUpdate.condition_types"
                      :items="and_or_options"
                      item-text="value"
                      item-value="key"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label=""
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun element trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(condition, index) in configurationToUpdate.conditions"
                  :key="index"
                >
                  <p class="m-0" hidden>
                    {{ (condition.index = index) }}
                  </p>
                  <v-col cols="4">
                    <!-- COLONNE -->
                    <v-autocomplete
                      :title="conputedSelectedColumn(condition.column)"
                      :clearable="true"
                      v-model="condition.column"
                      :items="[...computedGetColumns]"
                      item-text="value"
                      item-value="key"
                      color="#704ad1"
                      item-color="#704ad1"
                      class="mb-1"
                      placeholder="Séléctionnez"
                      :persistent-placeholder="true"
                      label="Colonne"
                      required
                      dense
                      :auto-focus="false"
                      no-data-text="Aucune colonne trouvée"
                      outlined
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      @changeValue="
                        condition.value = checkColomnConditionConfig(
                          condition.column
                        )
                          ? null
                          : condition.value
                      "
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    :cols="
                      condition.operation != 'ISNULL' &&
                      condition.operation != 'ISNOTNULL'
                        ? 3
                        : 6
                    "
                  >
                    <!-- OPERATION  -->
                    <v-select
                      :clearable="true"
                      v-model="condition.operation"
                      :items="operation_options"
                      item-text="text"
                      item-value="value"
                      dense
                      outlined
                      hide-details
                      class="mb-4"
                      label="Opération"
                      item-color="#704ad1"
                      color="#704ad1"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner"
                      no-data-text="Aucun element trouvé"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-select>
                  </v-col>

                  <v-col
                    cols="3"
                    v-if="
                      condition.operation != 'ISNULL' &&
                        condition.operation != 'ISNOTNULL'
                    "
                  >
                    <!-- VALUE  -->
                    <!-- COLONNE -->
                    <v-autocomplete
                      v-if="checkColomnConditionConfig(condition.column)"
                      :clearable="true"
                      v-model="condition.value"
                      :items="
                        condition.column == 'organisme'
                          ? getListOrganismeObligee
                          : getProFilials
                      "
                      item-text="name"
                      item-value="name"
                      color="#704ad1"
                      item-color="#704ad1"
                      class="mb-1"
                      placeholder="Séléctionnez"
                      :persistent-placeholder="true"
                      label="Valeur"
                      required
                      dense
                      :auto-focus="false"
                      no-data-text="Aucun Valeur trouvé"
                      outlined
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-autocomplete>
                    <v-text-field
                      v-else
                      :label="'Valeur'"
                      :persistent-placeholder="true"
                      v-model="condition.value"
                      required
                      outlined
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                  <!-- DELETE CONDITION  -->
                  <v-col cols="2">
                    <v-btn
                      class="mx-2 button-add"
                      small
                      @click="deleteCondition(index, 'update')"
                    >
                      <v-icon dark>
                        mdi-delete
                      </v-icon>
                    </v-btn></v-col
                  >
                </v-row>
                <template
                  v-if="
                    configurationToUpdate.filiale_id != null &&
                      configurationToUpdate.date_debut != null
                  "
                >
                  <v-row><b>Specifics:</b> </v-row>

                  <v-row class="d-flex justify-content-between">
                    <v-col cols="2"
                      ><b>
                        <v-switch
                          class="check-style-custom-obligee-mail"
                          v-model="checkAllUpdate"
                          :value="true"
                          :unchecked-value="false"
                          color="#704ad1"
                          @change="changeAllCheckColumnsUpdate"
                        >
                          <template v-slot:label>
                            <span class="title-check-commercial-mail">
                              Tous</span
                            >
                          </template>
                        </v-switch></b
                      >
                    </v-col>
                    <v-col cols="2"
                      ><b>
                        <v-switch
                          class="check-style-custom-obligee-mail"
                          v-model="specific_master"
                          :value="true"
                          :unchecked-value="false"
                          color="#704ad1"
                          @change="changeMastersFormmat('update')"
                        >
                          <template v-slot:label>
                            <span class="title-check-commercial-mail">
                              Master</span
                            >
                          </template>
                        </v-switch></b
                      >
                    </v-col>
                    <v-col cols="1"></v-col>
                    <v-col cols="7"
                      ><v-text-field
                        label="Rechercher"
                        v-model="search"
                        outlined
                        clearable
                        hide-details
                        :persistent-placeholder="true"
                        :placeholder="'Rechercher'"
                        color="#704ad1"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="scroll-specifics-params">
                    <v-col
                      cols="6"
                      v-for="(option, index) in computedSearchListSpecifics"
                      :key="'option-check-1' + index"
                    >
                      <v-checkbox
                        :label="option.name"
                        class="checked-tva margin-none"
                        color="#704ad1"
                        :value="option.option"
                        v-model="configurationToUpdate.specifics"
                        hide-details
                        @change="checkErrorsForUpdate(option)"
                      >
                      </v-checkbox>

                      <template v-if="specific_master && option.master">
                        <v-checkbox
                          v-for="(master, indexM) in option.societe_master"
                          :key="'option-check-2' + indexM"
                          :label="master.name"
                          class="checked-tva margin-none ml-4"
                          color="#704ad1"
                          hide-details
                          :value="master.option"
                          v-model="configurationToUpdate.specifics"
                          @change="checkErrorsForUpdate"
                        ></v-checkbox>
                      </template>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleUpdateConfiguration"
            :loading="getIsLoadingConfigFraisTh"
            :disabled="getIsLoadingConfigFraisTh"
            :class="{ loader: getIsLoadingConfigFraisTh }"
          >
            Valider
          </v-btn>
          <v-btn text @click.prevent.stop="handleModalConfig('update', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END MODAL UPDATE CONFIGURATION  -->
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import domain from '@/environment';

export default {
  data() {
    return {
      typeProduct: [
        { value: 'prestation', text: 'Préstation' },
        { value: 'marchandise', text: 'Marchandise' }
      ],
      getAllTvas: [],
      isDatePickerDebutVisibleConfig: false,
      isDatePickerFinVisibleConfig: false,
      isDatePickerDebutVisibleUpdateConfig: false,
      isDatePickerFinVisibleUpdateConfig: false,
      addConfigurationModal: false,
      updateConfigurationModal: false,
      selectedTable: [],
      expanded: [],
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      isDatePickerActiveVisible: false,
      menu: false,
      specific_master: false,
      specific_master_dup: false,
      active_date: null,
      active: null,
      isLocalStorageactive: null,
      commercials: [],
      isLocalStoragecommercials: [],
      famille: null,
      isLocalStoragfamille: null,
      vendeurs: [],
      isLocalStoragevendeurs: [],
      errorConfig: null,
      acheteur_type_list: [
        { id: 'frais', name: 'frais' },
        { id: "bureau d'etude", name: "Bureau d'etude" }
      ],
      search: null,
      baseURL: domain,
      isTextCopied: false,
      formule_types_options: [
        { text: 'Min', value: 'min' },
        { text: 'Max', value: 'max' }
      ],
      coef_type_reduction_options: [
        { text: 'Pourcentage', value: 'pourcentage' },
        { text: 'Montant fix', value: 'montant fix' },
        { text: 'Prix unitaire', value: 'prix unitaire' }
      ],
      and_or_options: [
        { text: 'OU', value: 'or' },
        { text: 'ET', value: 'and' }
      ],
      operation_options: [
        {
          text: 'Egale à',
          value: '=='
        },
        {
          text: 'Pas égale à',
          value: '!='
        },
        {
          text: 'Supérieur à',
          value: '>'
        },
        {
          text: 'Inférieur à',
          value: '<'
        },
        {
          text: 'Superieur ou égale à',
          value: '>='
        },
        {
          text: 'Inferieur ou égale à',
          value: '<='
        },
        {
          text: 'Contient',
          value: 'str_contains'
        },
        {
          text: 'ne contient pas',
          value: '!str_contains'
        },
        {
          text: 'est null',
          value: 'ISNULL'
        },
        {
          text: "n'est pas null",
          value: 'ISNOTNULL'
        }
      ],
      fields: [
        {
          value: 'details',
          text: '',
          class: 'sticky-header-1',
          width: '40px !important'
        },
        {
          value: 'name',
          text: 'Societé',
          class: 'sticky-header-2'
        },
        {
          value: 'description',
          text: 'Description'
        },
        {
          value: 'coef',
          text: 'Coefficient'
        },
        {
          value: 'label',
          text: 'Label'
        },

        {
          value: 'date_debut',
          text: 'Début de configuration'
        },
        {
          value: 'date_fin',
          text: 'Fin de configuration'
        },
        {
          value: 'main_family',
          text: 'Famille'
        },
        {
          value: 'user',
          text: 'Créer par'
        },
        {
          value: 'action',
          text: 'Action',
          thClass: 'th-col-actions',
          tdClass: 'td-col-actions'
        }
      ],
      validateToUpdateconf: false,
      validateToAddconf: false,
      checkAll: false,
      checkAllUpdate: false,
      typeConfigToUse: 'add',
      configurationToAdd: {
        commercials: [],
        main_family: 'support',
        filiale_id: null,
        grouped: 'dossier',
        group_by: null,
        facture_group_by: null,
        tva: 20,
        acheteur_id: null,
        coef: null,
        label: null,
        condition_types: 'and',
        formule_types: 'max',
        coef_type: 'pourcentage',
        reduction: 0,
        reduction_type: 'pourcentage',
        date_debut: null,
        date_fin: null,
        coefs: [
          {
            coef: 0,
            label: null,
            type: 'prestation'
          }
        ],
        conditions: [],
        specifics: [],
        formules: [{ formule: null }],
        acheteur_type: 'frais',
        description: null,
        facture_description: null,

        famillies: []
      },
      dateEndForSearch: null,
      dateStartforSearch: null,
      nom_societe: null,
      configuration: null,
      configurationToUpdate: null,
      perPage: 20,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getAllFilialSupport',
      'getAllProFilial',
      'getAllNumColums',
      'getAllColumns',
      'addNewConfiguration',
      'checkPeriod',
      'fetchAllconfigurations',
      'updateConfiguration',
      'initErrors',
      'BlockUnblockConfigFraisDync',
      'fetchAllFammilleConfig',
      'checkFormuleConfigFraisDynamicMethod',
      'getFamilles',
      'fetchAllFammilleConfig'
    ]),
    addTvaAction(data) {
      this.getAllTvas.push(data);
    },
    setTvaList(id) {
      let item = this.computedNotCommercial.find(element => element.id === id);
      this.getAllTvas = item.tva;
    },
    async handleModalConfig(method, action, data) {
      if (method == 'add') {
        if (action == 'show') {
          this.addConfigurationModal = true;
        } else {
          this.addConfigurationModal = false;
          this.resetModal();
        }
      }

      if (method == 'update') {
        if (action == 'show') {
          this.configuration = data;
          this.configurationToUpdate = JSON.parse(JSON.stringify(data));
          this.configurationToUpdate.specifics = this.configurationToUpdate?.specifics?.map(
            item => ({
              filiale_id: item.filiale_id
            })
          );
          this.configurationToUpdate.commercials = this.configurationToUpdate.commercials.map(
            item => ({
              id: item.commercial_id,
              is_societe_support: 0,
              master: false,
              societe_master: [],
              type: 'particulier',
              name: item.commercial
            })
          );
          this.checkAllUpdate =
            this.configurationToUpdate.specifics.length !=
            this.computedFormatFilialList.length
              ? false
              : true;
          this.fetchAllFammilleConfig({});
          this.updateConfigurationModal = true;
          let objToSend = {
            id: this.configurationToUpdate.id,
            filiale_id: this.configurationToUpdate.filiale_id,
            date_debut:
              this.configurationToUpdate.date_debut != null
                ? moment(
                    this.configurationToUpdate.date_debut,
                    'DD-MM-YYYY'
                  ).format('YYYY-MM-DD')
                : this.configurationToUpdate.date_debut,
            date_fin:
              this.configurationToUpdate.date_fin != null
                ? moment(
                    this.configurationToUpdate.date_fin,
                    'DD-MM-YYYY'
                  ).format('YYYY-MM-DD')
                : this.configurationToUpdate.date_fin,
            specific_ids: [],
            label: this.configurationToUpdate.label,
            acheteur_id: this.configurationToUpdate.acheteur_id,
            coef_type: this.configurationToUpdate.coef_type
          };
          this.configurationToUpdate.specifics.map(item =>
            objToSend.specific_ids.push(item.filiale_id)
          );
          if (this.configurationToUpdate.specifics.length > 0) {
            const response = await this.checkPeriod(objToSend);
            if (response?.success) {
              this.validateToUpdateconf = true;
            } else {
              this.validateToUpdateconf = false;
            }
          }
        } else {
          this.updateConfigurationModal = false;
          this.configuration = null;
          this.configurationToUpdate = null;
          this.resetModal();

          // this.updateFamille = false;
          // this.famillyToUpdate = null;
          // this.oldFamilleToUpdate = null;
        }
      }
    },
    getDataForRow(data) {
      data.detailsShowing = !data.detailsShowing;
      if (data.detailsShowing == true) {
        if (!this.expanded.includes(data)) {
          this.expanded.push(data);
        }
      }
    },
    SelectAllCommercials(check) {
      if (check == true) {
        this.configurationToAdd.commercials = this.computedCommercial;
      } else {
        this.configurationToAdd.commercials = [];
      }
    },
    SelectAllUpdateCommercials(check) {
      if (check == true) {
        this.configurationToUpdate.commercials = this.computedCommercial;
      } else {
        this.configurationToUpdate.commercials = [];
      }
    },
    SelectAllFamilles(check) {
      if (check == true) {
        this.configurationToAdd.famillies = this.getListFamilleConfigFrias;
      } else {
        this.configurationToAdd.famillies = [];
      }
    },
    SelectAllFamillesUpdate(check) {
      if (check == true) {
        this.configurationToUpdate.famillies = this.getListFamilleConfigFrias;
      } else {
        this.configurationToUpdate.famillies = [];
      }
    },
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      // if (this[('isLocalStorage', filter)].indexOf(item)>=0) {
      //   this['isLocalStorage' + filter].splice(
      //     this[('isLocalStorage', filter)].indexOf(item),
      //     1
      //   );
      // }
      this.isLocalStoragevendeurs = this.vendeurs;
      this.isLocalStoragecommercials = this.commercials;
      this[fct]();
    },
    initialFamilly() {
      if (this.configurationToAdd.main_family == false) {
        this.configurationToAdd.main_family = 'support';
      }
    },
    changeInitialFamily() {
      if (this.configurationToUpdate.main_family == false) {
        this.configurationToUpdate.main_family = 'support';
      }
    },
    changeState() {
      if (this.configurationToAdd.grouped == false) {
        this.configurationToAdd.grouped = 'dossier';
      }
    },
    stateInitial() {
      if (this.configurationToUpdate.grouped == false) {
        this.configurationToUpdate.grouped = 'dossier';
      }
    },
    async checkFormuleConfigFraisDynamic(type, index) {
      let value =
        type === 'add'
          ? this.configurationToAdd.formules[index].formule
          : this.configurationToUpdate.formules[index].formule;
      const response = await this.checkFormuleConfigFraisDynamicMethod({
        formule: value
      });
      if (response && response.success == false) {
        if (type === 'add') {
          this.configurationToAdd.formules[index].error = response.error;
        } else {
          this.configurationToUpdate.formules[index].error = response.error;
        }
      } else {
        if (type === 'add') {
          this.configurationToAdd.formules[index].error = null;
        } else {
          this.configurationToUpdate.formules[index].error = null;
        }
      }
    },
    async handelFilter() {
      await this.fetchAllconfigurations({
        date_debut: this.dateStartforSearch,
        date_fin: this.dateEndForSearch,
        search: this.nom_societe,
        per_page: this.perPage,
        page: this.page,
        vendeurs: this.vendeurs,
        active: this.active,
        active_date: this.active_date,
        famille: this.famille,
        commercials: this.commercials
      });
    },
    openModalConfig() {
      this.checkAll = true;
      this.specific_master = false;
      this.changeAllCheckColumns();
      this.fetchAllFammilleConfig({});
    },
    validerPopover() {
      this.vendeurs = this.isLocalStoragevendeurs;
      this.active = this.isLocalStorageactive;
      this.famille = this.isLocalStoragfamille;
      this.commercials = this.isLocalStoragecommercials;
      this.handelFilter();
      this.menu = false;
    },
    closePopover() {
      this.menu = false;
      this.vendeurs = [];
      this.famille = null;
      this.active = null;
      this.commercials = [];
      this.handelFilter();
    },
    async searchFilter(e, colonne, reset = false) {
      if ((e || e == null) && colonne) {
        this[colonne] = e;
      }

      this.handelFilter(reset);
    },
    CopyFields(payload) {
      var textarea = document.createElement('textarea');
      textarea.textContent = '$' + payload;
      document.body.appendChild(textarea);
      var selection = document.getSelection();
      var range = document.createRange();
      range.selectNode(textarea);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();

      document.body.removeChild(textarea);
      this.isTextCopied = true;
      setTimeout(() => {
        this.isTextCopied = false;
      }, 800);
    },
    changeAllCheckColumns() {
      let table = [];
      if (this.checkAll) {
        if (this.specific_master) {
          this.computedNotCommercial
            .filter(i => i.master)
            .map(item => {
              table.push({
                filiale_id: item.id
              });
              item.societe_master.map(m =>
                table.push({
                  filiale_id: m.id
                })
              );
            });
        } else {
          table = this.computedNotCommercial.map(item => ({
            filiale_id: item.id
          }));
        }
      } else {
        if (this.specific_master) {
          let masterIds = this.computedNotCommercial
            .filter(i => i.master)
            .map(i => i.societe_master)
            .flat()
            .map(i => i.id);
          table = this.computedNotCommercial
            .filter(i => !masterIds.includes(i.id) && !i.master)
            .map(i => ({ filiale_id: i.id }));
        }
      }
      this.configurationToAdd.specifics = table;
    },
    changeMastersFormmat(type) {
      if (this.specific_master == true) {
        if (type == 'add') {
          this.checkAll = false;
          this.configurationToAdd.specifics = [];
        } else if (type == 'update') {
          this.checkAllUpdate = false;
          this.configurationToUpdate.specifics = [];
        }
      }
    },
    changeAllCheckColumnsUpdate() {
      let table = [];
      if (this.checkAllUpdate) {
        if (this.specific_master) {
          this.computedNotCommercial
            .filter(i => i.master)
            .map(item => {
              table.push({
                filiale_id: item.id
              });
              item.societe_master.map(m =>
                table.push({
                  filiale_id: m.id
                })
              );
            });
        } else {
          table = this.computedNotCommercial.map(item => ({
            filiale_id: item.id
          }));
        }
      } else {
        if (this.specific_master) {
          let masterIds = this.computedNotCommercial
            .filter(i => i.master)
            .map(i => i.societe_master)
            .flat()
            .map(i => i.id);
          table = this.computedNotCommercial
            .filter(i => !masterIds.includes(i.id) && !i.master)
            .map(i => ({ filiale_id: i.id }));
        }
      }
      this.configurationToUpdate.specifics = table;
    },
    addNewCondition(type) {
      if (type === 'add') {
        this.configurationToAdd.conditions.push({
          index: null,
          column: null,
          operation: null,
          value: null
        });
      } else {
        this.configurationToUpdate.conditions.push({
          index: null,
          column: null,
          operation: null,
          value: null
        });
      }
    },
    addNewFormule(type) {
      if (type === 'add') {
        this.configurationToAdd.formules.push({
          formule: null,
          error: null
        });
      } else {
        this.configurationToUpdate.formules.push({
          formule: null,
          error: null
        });
      }
    },
    addNewcoeffition(type) {
      if (type === 'add') {
        this.configurationToAdd.coefs.push({
          coef: 0,
          label: null,
          type: 'prestation'
        });
      } else {
        this.configurationToUpdate.coefs.push({
          coef: 0,
          label: null,
          type: 'prestation'
        });
      }
    },
    deleteCondition(index, type) {
      if (type === 'add') {
        this.configurationToAdd.conditions.splice(index, 1);
      } else {
        this.configurationToUpdate.conditions.splice(index, 1);
      }
    },
    deleteFormule(index, type) {
      if (type === 'add') {
        this.configurationToAdd.formules.splice(index, 1);
      } else {
        this.configurationToUpdate.formules.splice(index, 1);
      }
    },
    deleteCoefficient(index, type) {
      if (type === 'add') {
        this.configurationToAdd.coefs.splice(index, 1);
      } else {
        this.configurationToUpdate.coefs.splice(index, 1);
      }
    },
    resetModal() {
      this.errorConfig = null;
      this.search = null;
      this.configurationToAdd = {
        commercials: [],
        main_family: 'support',
        grouped: 'dossier',
        group_by: null,
        facture_group_by: null,
        filiale_id: null,
        tva: 20,
        acheteur_id: null,
        coef: null,
        label: null,
        condition_types: 'and',
        formule_types: 'max',
        coef_type: 'pourcentage',
        reduction: 0,
        reduction_type: 'pourcentage',
        date_debut: null,
        date_fin: null,
        coefs: [
          {
            coef: 0,
            label: null,
            type: 'prestation'
          }
        ],
        conditions: [],
        specifics: [],
        formules: [{ formule: null, error: null }],
        acheteur_type: 'frais',
        description: null,
        facture_description: null
      };
      this.configurationToUpdate = null;
      this.initErrors();
    },
    async pagination(paginate) {
      this.page = paginate;
      this.handelFilter();
    },
    async changePerPage() {
      this.page = 1;
      this.handelFilter();
    },

    hideModal(ref) {
      // this.$refs[ref].hide();
      this.resetModal();
    },
    async updateConfigurationItem(data) {
      this.configuration = data;

      this.configurationToUpdate = JSON.parse(JSON.stringify(data));
      this.configurationToUpdate.specifics = this.configurationToUpdate?.specifics?.map(
        item => ({
          filiale_id: item.filiale_id
        })
      );
      this.configurationToUpdate.commercials = this.configurationToUpdate.commercials.map(
        item => ({
          id: item.commercial_id,
          is_societe_support: 0,
          master: false,
          societe_master: [],
          type: 'particulier',
          name: item.commercial
        })
      );
      this.checkAllUpdate =
        this.configurationToUpdate.specifics.length !=
        this.computedFormatFilialList.length
          ? false
          : true;
      this.fetchAllFammilleConfig({});
      this.$refs['updateConfigurationModal'].show();
      // if (this.configurationToUpdate.date_debut != null) {
      //   this.configurationToUpdate.date_debut = moment(
      //     this.configurationToUpdate.date_debut,
      //     'DD-MM-YYYY'
      //   ).format('YYYY-MM-DD');
      // }

      // if (this.configurationToUpdate.date_fin != null) {
      //   this.configurationToUpdate.date_fin = moment(
      //     this.configurationToUpdate.date_fin,
      //     'DD-MM-YYYY'
      //   ).format('YYYY-MM-DD');
      // }

      let objToSend = {
        id: this.configurationToUpdate.id,
        filiale_id: this.configurationToUpdate.filiale_id,
        date_debut:
          this.configurationToUpdate.date_debut != null
            ? moment(
                this.configurationToUpdate.date_debut,
                'DD-MM-YYYY'
              ).format('YYYY-MM-DD')
            : this.configurationToUpdate.date_debut,
        date_fin:
          this.configurationToUpdate.date_fin != null
            ? moment(this.configurationToUpdate.date_fin, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : this.configurationToUpdate.date_fin,
        specific_ids: [],
        label: this.configurationToUpdate.label,
        acheteur_id: this.configurationToUpdate.acheteur_id,
        coef_type: this.configurationToUpdate.coef_type
      };
      this.configurationToUpdate.specifics.map(item =>
        objToSend.specific_ids.push(item.filiale_id)
      );
      if (this.configurationToUpdate.specifics.length > 0) {
        const response = await this.checkPeriod(objToSend);
        if (response?.success) {
          this.validateToUpdateconf = true;
        } else {
          this.validateToUpdateconf = false;
        }
      }
    },
    async BlockUnblockConfigclick(item) {
      await this.BlockUnblockConfigFraisDync(item);
    },
    async handleUpdateConfiguration() {
      this.errorConfig = null;
      if (
        this.configurationToUpdate.famillies.length == 0 &&
        this.configurationToUpdate.main_family == 'support'
      ) {
        this.errorConfig = 'sélectionne au moins une famille';
      } else {
        this.configurationToUpdate.grouped =
          this.configurationToUpdate.grouped == 'dossier' ? 'non' : 'oui';
        this.configurationToUpdate.payment_call =
          this.configurationToUpdate.payment_call == true ? 'oui' : 'non';
        this.configurationToUpdate.reduction = parseFloat(
          this.configurationToUpdate.reduction
        );
        const response = await this.updateConfiguration({
          configuration: this.configuration,
          configurationToUpdate: this.configurationToUpdate
        });
        if (response.success) {
          this.handleModalConfig('update', 'hide');
        }
      }
    },
    disabledStartDate(date) {
      return (
        this.configurationToAdd.date_fin &&
        date >= new Date(this.configurationToAdd.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.configurationToAdd.date_debut &&
        date < new Date(this.configurationToAdd.date_debut)
      );
    },

    disabledStartDateFilter(date) {
      return (
        this.dateEndForSearch &&
        moment(date, 'YYYY-MM-DD') >= new Date(this.dateEndForSearch)
      );
    },
    disabledEndDateFiltre(date) {
      return (
        this.dateStartforSearch &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') <
          new Date(this.dateStartforSearch)
      );
    },
    disabledStartDateUpdate(date) {
      return (
        this.configurationToUpdate.date_fin &&
        date >= new Date(this.configurationToUpdate.date_fin)
      );
    },
    disabledEndDateUpdate(date) {
      return (
        this.configurationToUpdate.date_debut &&
        date < new Date(this.configurationToUpdate.date_debut)
      );
    },
    async duplicateConfiguration(data) {
      this.typeConfigToUse = 'dup';
      this.configurationToAdd = JSON.parse(JSON.stringify(data));
      let spec = this.configurationToAdd.specifics;
      this.configurationToAdd.specifics = [];
      this.configurationToAdd.specifics = spec.map(item => ({
        filiale_id: item.filiale_id
      }));
      this.configurationToAdd.commercials = this.configurationToAdd.commercials.map(
        item => ({
          id: item.commercial_id,
          is_societe_support: 0,
          master: false,
          societe_master: [],
          type: 'particulier',
          name: item.commercial
        })
      );
      this.configurationToAdd = this.configurationToAdd;
      this.checkAll =
        this.configurationToAdd.specifics.length !=
        this.computedFormatFilialList.length
          ? false
          : true;
      let tva = this.computedNotCommercial.filter(
        i => i.id == this.configurationToAdd.filiale_id
      )[0].tva;
      this.getAllTvas = tva ? tva : [];
      this.fetchAllFammilleConfig({});
      this.handleModalConfig('add', 'show');

      // this.$refs['addConfigurationModal'].show();
      // if (this.configurationToAdd.date_debut != null) {
      //   this.configurationToAdd.date_debut = moment(
      //     this.configurationToAdd.date_debut,
      //     'DD-MM-YYYY'
      //   ).format('YYYY-MM-DD');
      // }
      // if (this.configurationToAdd.date_fin != null) {
      //   this.configurationToAdd.date_fin = moment(
      //     this.configurationToAdd.date_fin,
      //     'DD-MM-YYYY'
      //   ).format('YYYY-MM-DD');
      // }

      let objToSend = {
        id: null,
        filiale_id: this.configurationToAdd.filiale_id?.id,
        date_debut:
          this.configurationToAdd.date_debut != null
            ? moment(this.configurationToAdd.date_debut, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : this.configurationToAdd.date_debut,
        date_fin:
          this.configurationToAdd.date_fin != null
            ? moment(this.configurationToAdd.date_fin, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : this.configurationToAdd.date_fin,
        specific_ids: [],
        acheteur_id: this.configurationToAdd.acheteur_id,
        coef_type: this.configurationToAdd.coef_type
      };
      this.configurationToAdd.specifics.map(item =>
        objToSend.specific_ids.push(item.filiale_id)
      );
      if (this.configurationToAdd.specifics.length > 0) {
        const response = await this.checkPeriod(objToSend);
        if (response?.success) {
          this.validateToAddconf = true;
        } else {
          this.validateToAddconf = false;
        }
      }
    },
    async handleSubmitConfiguration() {
      this.errorConfig = null;
      if (
        this.configurationToAdd.famillies.length == 0 &&
        this.configurationToAdd.main_family == 'support'
      ) {
        this.errorConfig = 'sélectionne au moins une famille';
      } else if (
        this.configurationToAdd.famillies.length == 0 &&
        this.configurationToAdd.main_family == 'support'
      ) {
        this.errorConfig = 'sélectionne au moins une famille';
      } else {
        this.configurationToAdd.grouped =
          this.configurationToAdd.grouped == 'dossier' ? 'non' : 'oui';
        this.configurationToAdd.payment_call =
          this.configurationToAdd.payment_call == true ? 'oui' : 'non';
        this.configurationToAdd.reduction = parseFloat(
          this.configurationToAdd.reduction
        );
        let data = this.configurationToAdd;
        // if(this.typeConfigToUse=='dup'){
        //   data.commercials
        // }
        const response = await this.addNewConfiguration(data);
        if (response?.success) {
          this.handleModalConfig('add', 'hide');
        }
      }
    },
    async checkErrorsForAdd(option) {
      if (option && option.master) {
        let testTable = this.configurationToAdd.specifics.map(
          i => i.filiale_id
        );
        option.societe_master.forEach(i => {
          if (!testTable.includes(i.id)) {
            this.configurationToAdd.specifics.push({ filiale_id: i.id });
          }
        });
      }
      this.checkAll =
        this.configurationToAdd.specifics.length !=
        this.computedFormatFilialList.length
          ? false
          : true;

      let objToSend = {
        id: null,
        filiale_id: this.configurationToAdd.filiale_id,
        date_debut:
          this.configurationToAdd.date_debut != null
            ? moment(this.configurationToAdd.date_debut, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : this.configurationToAdd.date_debut,
        date_fin:
          this.configurationToAdd.date_fin != null
            ? moment(this.configurationToAdd.date_fin, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : this.configurationToAdd.date_fin,
        specific_ids: [],
        acheteur_id: this.configurationToAdd.acheteur_id,
        coef_type: this.configurationToAdd.coef_type
      };
      this.configurationToAdd.specifics.map(item =>
        objToSend.specific_ids.push(item.filiale_id)
      );
      if (this.configurationToAdd.specifics.length > 0) {
        const response = await this.checkPeriod(objToSend);
        if (response?.success) {
          this.validateToAddconf = true;
          this.initErrors();
        } else {
          this.validateToAddconf = false;
        }
      }
    },
    async checkErrorsForUpdate(option) {
      if (option && option.master) {
        let testTable = this.configurationToUpdate.specifics.map(
          i => i.filiale_id
        );
        option.societe_master.forEach(i => {
          if (!testTable.includes(i.id)) {
            this.configurationToUpdate.specifics.push({ filiale_id: i.id });
          }
        });
      }
      this.checkAllUpdate =
        this.configurationToUpdate.specifics.length ==
        this.computedFormatFilialList.length
          ? true
          : false;
      let objToSend = {
        id: this.configurationToUpdate.id,
        filiale_id: this.configurationToUpdate.filiale_id,
        date_debut:
          this.configurationToUpdate.date_debut != null
            ? moment(
                this.configurationToUpdate.date_debut,
                'DD-MM-YYYY'
              ).format('YYYY-MM-DD')
            : this.configurationToUpdate.date_debut,
        date_fin:
          this.configurationToUpdate.date_fin != null
            ? moment(this.configurationToUpdate.date_fin, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : this.configurationToUpdate.date_fin,
        specific_ids: [],
        label: this.configurationToUpdate.label,
        acheteur_id: this.configurationToUpdate.acheteur_id,
        coef_type: this.configurationToUpdate.coef_type
      };
      this.configurationToUpdate.specifics.map(item =>
        objToSend.specific_ids.push(item.filiale_id)
      );
      if (this.configurationToUpdate.specifics.length > 0) {
        const response = await this.checkPeriod(objToSend);
        if (response?.success) {
          this.validateToUpdateconf = true;
          this.initErrors();
        } else {
          this.validateToUpdateconf = false;
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'getFilialsSupport',
      'getProFilials',
      'getNumeriqCulomn',
      'getColumns',
      'getIsLoadingConfigFraisTh',
      'getConfigurationError',
      'getAllConfigurations',
      'getTotalRowConf',
      'getErrorForSpecific',
      'getListFamilleConfigFrias',
      'getLoadingFamilleConfig',
      // 'getAllTvas',
      'checkPermission',
      'familles',
      'getListOrganismeObligee',
      'loadingFiliale',
      'countNonEmptyArrays'
    ]),
    computedLengthFilter() {
      return this.countNonEmptyArrays(this.commercials, this.vendeurs);
    },
    computedTotal() {
      if (this.getTotalRowConf) {
        return Math.ceil(this.getTotalRowConf / this.perPage);
      }
      return this.getTotalRowConf;
    },
    computedgetNumeriqCulomn() {
      if (this.getNumeriqCulomn) {
        return Object.keys(this.getNumeriqCulomn).map(key => ({
          key,
          value: this.getNumeriqCulomn[key]
        }));
      }
      return [];
    },
    computedgetAllTvas() {
      return this.getAllTvas.filter(i => i.is_active == 1);
    },
    computedCheckSocieteSupport() {
      return (
        this.configurationToAdd.filiale_id &&
        this.getProFilials.filter(
          i => i.id == this.configurationToAdd.filiale_id
        ).length &&
        this.getProFilials.filter(
          i => i.id == this.configurationToAdd.filiale_id
        )[0].is_societe_support == 0 &&
        this.configurationToAdd.main_family == 'support'
      );
    },
    returnTableChips() {
      if (
        this.dateStartforSearch !== null ||
        this.dateEndForSearch !== null ||
        this.active_date !== null ||
        this.famille !== null ||
        this.active !== null ||
        (this.nom_societe !== null && this.nom_societe !== '')
      ) {
        return true;
      }
      return false;
    },
    returnTableFiltre() {
      if (this.vendeurs.length || this.commercials.length) {
        return true;
      }
      return false;
    },
    computedSelectAllComercials() {
      return function(commercial) {
        if (commercial.length == this.computedCommercial.length) {
          return true;
        }
        return false;
      };
    },
    computedCommercial() {
      return this.getProFilials.filter(i => i.type == 'particulier');
    },
    computedNotCommercial() {
      return this.getProFilials.filter(i => i.type != 'particulier');
    },
    computedGetColumns() {
      return Object.keys(this.getColumns).map(i => {
        return { value: this.getColumns[i], key: i };
      });
    },
    checkColomnConditionConfig() {
      return function(value) {
        return (
          value &&
          (value == 'installeur' ||
            value == 'organisme' ||
            value == 'regie' ||
            value == 'sous_regie' ||
            value == 'super_regie')
        );
      };
    },
    computedListFamille() {
      return this.familles.map(i => {
        return { value: i, text: i };
      });
    },
    conputedSelectedColumn() {
      return function(key) {
        return key != null ? this.getColumns[key] : '';
      };
    },
    computedSelectAllFamilles() {
      return function(familles) {
        if (familles?.length == this.getListFamilleConfigFrias?.length) {
          return true;
        }
        return false;
      };
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 340 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 250 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },

    computedCheckChipFilter() {
      if (this.vendeurs?.length > 0) {
        return true;
      }
      return false;
    },
    computedFormule() {
      return function(data) {
        if (data?.formules?.length) {
          let formule = '';
          formule =
            formule + data?.formules?.length == 1
              ? ''
              : data.formule_types + '(';
          data.formules.forEach((element, index) => {
            index < data.formules.length - 1
              ? (formule = formule + '(' + element.formule + ')' + ',')
              : (formule = formule + '(' + element.formule + ')');
          });
          data?.formules?.length == 1
            ? (formule = formule + '')
            : (formule = formule + ')');
          Object.keys(this.getNumeriqCulomn).forEach(key => {
            formule = formule.replaceAll('$' + key, this.getNumeriqCulomn[key]);
          });
          return formule;
        }
        return '';
      };
    },
    computedFormatFilialList() {
      return this.getProFilials
        .filter(i => i.type != 'particulier')
        .map(item => ({
          ...item,
          filiale_id: item.id
        }));
    },
    computedSearchListSpecifics() {
      let table = this.specific_master
        ? this.computedFormatFilialList.filter(
            i => i.master == this.specific_master
          )
        : this.computedFormatFilialList;
      if (this.search != null) {
        table = table.filter(item =>
          item.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      if (this.master == true) {
        table = table.filter(item => item.master == true);
      }

      return table.map(i => {
        let iteem = i;
        if (iteem.master) {
          iteem.societe_master = iteem.societe_master.map(m => {
            return { ...m, option: { filiale_id: m.id } };
          });
        }

        return { ...iteem, option: { filiale_id: i.filiale_id } };
      });
    },
    computedDisabledCoeffition() {
      if (this.configurationToAdd.coefs.length > 0) {
        return true;
      }
      return false;
    },
    computedDidabledCoeffToUpdate() {
      if (this.configurationToUpdate?.coefs?.length > 0) {
        return true;
      }
      return false;
    },
    // computedFitredSociteConfiguer() {
    //   return function(data) {
    //     let tale = this.configurationToAdd.specifics.filter(
    //       item => item.filiale_id != null
    //     );
    //     if (data != null && tale.length) {
    //       tale = tale.filter(item => item.filiale_id != data);
    //     }
    //     let nameOfSpecificsFilials = [];
    //     if (tale.length) {
    //       nameOfSpecificsFilials = tale.map(({ filiale_id }) => filiale_id);
    //     }
    //     if (nameOfSpecificsFilials.length > 0) {
    //       let t = this.getProFilials.filter(
    //         item => !nameOfSpecificsFilials.includes(item.id)
    //       );
    //       return t;
    //     }
    //     return this.getProFilials;
    //   };
    // },
    getComputedConfugiration() {
      return this.configurationToAdd.coefs;
    },
    getComputedConfugirationUpdate() {
      return this.configurationToUpdate?.coefs;
    }
  },
  watch: {
    getComputedConfugiration: {
      handler: function() {
        if (this.configurationToAdd.coefs.length > 0) {
          let sum = 0;
          this.configurationToAdd.coefs.map(item => {
            sum += parseFloat(item.coef);
          });
          this.configurationToAdd.coef = sum;
        }
        // if (this.configurationToAdd.coefs.length === 0) {
        //   this.configurationToAdd.coef = 0;
        // }
      },
      deep: true
    },
    getComputedConfugirationUpdate: {
      handler: function() {
        let copyCoef = this.configurationToUpdate?.coef;
        if (this.configurationToUpdate?.coefs?.length > 0) {
          let sum = 0;
          this.configurationToUpdate?.coefs?.map(item => {
            sum += parseFloat(item.coef);
          });
          this.configurationToUpdate.coef = sum;
        }
        if (this.configurationToUpdate?.coefs?.length === 0) {
          this.configurationToUpdate.coef = copyCoef;
        }
      },
      deep: true
    }
  },
  components: {
    search: () => import('@/views/component/SearchInputComponent.vue'),
    IconFiltre: () => import('@/assets/img/IconFiltre.vue'),
    tva: () => import('@/views/component/tva/tva.vue'),
    ModalCreateFamille: () =>
      import('@/views/setting/configFrais/ModalCreatFamille.vue')
  },

  async mounted() {
    await this.fetchAllFammilleConfig({});
    await this.getFamilles();
    await this.getAllFilialSupport();
    await this.getAllProFilial();
    await this.getAllNumColums();
    await this.getAllColumns();
    this.handelFilter();
  }
};
</script>

<style scoped lang="scss">
.setting-filiale-sci {
  width: 100%;
  .button-danger-style,
  .button-succes-style {
    font-size: 9px;
    height: 20px;
    width: 20px;
    padding: 3px;
    margin: 3px;
    border-radius: 5px;
  }
  .table-configuration-variable-style {
    // max-height: calc(100vh - 304px) !important;
    // height: calc(100vh - 304px) !important;
    // margin-bottom: 0px;
  }
  .button-clocked-style {
    font-size: 9px;
    height: 20px;
    width: 20px;
    padding: 3px;
    margin: 3px;
  }
  .border-raduis {
    border-radius: 50%;
  }
  .input-focus-responsable-depot {
    width: 95%;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #dddde2;
    overflow: visible;
    text-overflow: ellipsis;
    outline: unset;
    padding: 4px;
    color: #5d5d5d;
    font-size: 11px;
    font-weight: 400;

    &:focus {
      background-color: #eeeef7;
    }
  }
  .input-focus-responsable-depot::placeholder {
    color: #c3c3d8;
    font-size: 9px;
  }
  .table-fiche {
    width: 100%;
    height: calc(100vh - 282px);
  }
  .newcolor {
    min-width: 100px;
  }
  .actions {
    position: sticky;
    right: -1px !important;
  }
  .three-points-concats {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
  }
  .back-ground-paire {
    background-color: #8d8cb7 !important;
  }
  .back-ground-impaire {
    background-color: #a0c5e8 !important;
  }
}
.bckground-icon-info {
  height: 22px;
  width: 22px;
  background-color: red;
  border-radius: 20px;
}
.info-icon {
  margin-top: 5px;
  color: #fff;
}

.custom-class-fields-av,
.custom-class-fields-av-update {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow-x: auto;
  text-align-last: left;
  max-height: calc(100% - 90px);
}
.CopieMsg {
  margin-left: 50px;
  color: #4d4bac;
  font-weight: 12px;
}
.custom-class-fields-av {
  .custom-control {
    width: 46%;
  }
}
.custom-class-fields-av-update {
  .custom-control {
    width: 46%;
  }
}
.loadingFetch {
  float: left;
  width: 25px;
  height: 25px;
  font-size: 10px;
}
.widthTitle {
  width: 170px;
}
.loaderPosition {
  display: flex;
  flex-direction: row;
}
.body-box-setting {
  display: flex;
  background-color: #f5f4fa;
  height: calc(100vh - 250px);
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 11px;
}
.Search-style-component {
  .search-box {
    position: relative;
    top: 0;
    right: 0px;
    background-color: #fdfbfb;
    padding: 5px;
    color: #a2a2a2;
    border: none;
    border-radius: 19px;

    .icon {
      position: absolute;
      font-size: 14px;
      top: 8px;
      left: 14px;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-right {
      position: absolute;
      font-size: 12px;
      top: 8px;
      right: 8px;
      color: #4d4bac;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-loader {
      color: #2dabe2;
    }
    .search-input {
      height: 18px;
      width: 160px;
      padding: 3px 3px 3px 33px;
      border-radius: 3px;
      box-sizing: border-box;
      box-shadow: none;
      border: none;
      opacity: 0.5;
      outline: none;
      color: #a2a2a2;
      font-size: 11px;
      // background-color: #f9f9f9;
      background-color: #fdfbfb;
      &:focus,
      &:active {
        opacity: 1;
        .icon {
          color: #2dabe2;
        }
      }
    }
  }

  .serachLocale {
    background-color: #f9f9f9;

    .search-input {
      background-color: #f9f9f9;
    }
  }
  label {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    color: #5d5d5d;
  }
}
// .date-picker-for-filter {
//   height: 30px;
//   border-radius: 0 20px 20px 0;
//   border: 1px solid #8d8cb7;
// }
.xmark-icon {
  color: #000;
  margin-top: 35px;
}
</style>
<style lang="scss">
.mt-21 {
  margin-left: -67px;
}
.table-rapport-style .table-configuration-variable-style {
  .button-warning-style {
    background-color: #c33025;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    padding: 6px 11px;
    border: none;
  }
  .button-update-style {
    background-color: #699d86;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    padding: 6px 11px;
    border: none;
  }
  .button-duplicate-style {
    background-color: #17a2b8;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    padding: 6px 11px;
    border: none;
  }
  .custom-checkbox {
    margin-right: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  td {
    width: 100px;
    min-width: 100px;
    line-height: 10px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    width: 135px;
    min-width: 135px;
    border-left: 1px solid white;
    border-right: 0px solid !important;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .th-col-client {
    z-index: 10 !important;
    position: sticky !important;
    left: 32px;
  }

  .td-col-client {
    z-index: 5 !important;
    position: sticky !important;
    left: 32px;
  }
  .th-col-actions {
    z-index: 10 !important;
    position: sticky !important;
    right: -10px;
  }

  .td-col-actions {
    z-index: 5 !important;
    position: sticky !important;
    right: -10px;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 100px;
    min-width: 100px;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  .background-week {
    justify-content: start !important;
    background-color: #e0e0eb;
    // color: #fff;
    vertical-align: middle;
    td {
      width: 135px;
      min-width: 135px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .col-plus-obligee,
    .td-col-actions,
    .td-col-client,
    .num-lot-td,
    .col-check-obligee,
    .td-total-coef-oblige {
      background-color: #e0e0eb;
      z-index: 6;
    }
    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff;
      display: table-cell;
    }
  }
  .align-td-item {
    padding: 0px !important;
    .item-details-style {
      justify-content: center;
    }
  }
}
</style>
<style lang="scss">
.table-fiche::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
.table-fiche {
  table {
    display: table !important;
  }
}

.table-fiche::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 25px;
  border: 12px solid transparent;
  background-clip: padding-box;
}

.table-fiche::-webkit-scrollbar-track {
  background: #f0eef8;
  border-radius: 30px;
  width: 15px;
  border-right: 10px solid white;
  border-left: 10px solid white;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}
.custom-select-vue-component-style {
  .multiselect__tags {
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #dddde2;
  }

  .multiselect__input,
  .multiselect__single {
    background: transparent;
  }
}
.labes-depot-name {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  text-align: start;
  color: #2a2a2a;
  margin-left: 3%;
  margin-top: 38px;
  margin-bottom: 1px;
  width: 30%;
}
.custom-checkbox {
  align-self: center;
  margin-right: 10px;
}
.image-preview {
  position: relative;
  display: inline;
  max-width: 112px;
}
.cutsom-modal-bootstrap-filiale-sci .modal-dialog {
  top: 0%;
  max-width: 70%;
  .type-doc-class > div {
    display: flex;
  }
}
.width-filter {
  width: 110px;
}
.date-picker-for-filter {
  border-radius: 0px 20px 20px 0px !important;
  height: 31px !important;
  border: 1px solid #8d8cb7 !important;
  outline: 0 !important;
  width: 220px !important;
  padding-left: 4px !important;
  color: #000 !important;
  background-color: #fff !important;
}
// .label-box-style {
//   background-color: #8d8cb7;
//   padding: 4px 3px;
//   border-radius: 22px 0px 0px 22px;
//   text-align: end;
//   height: 29px;
// }
.scroll-specifics-params {
  height: calc(100vh - 266px);
  overflow-y: auto;
  align-content: start;
  align-items: flex-start;
}
.scroll-specifics-params::-webkit-scrollbar-track {
  background: #f0eef8;
  border-radius: 30px;
  width: 15px;
  border-right: 10px solid white;
  border-left: 10px solid white;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}
.scroll-specifics-params::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
.scroll-specifics-params::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 25px;
  border: 12px solid transparent;
  background-clip: padding-box;
}
.table-config-fac {
  &.padding-table {
    padding-top: 19px;
  }
}
.v-dialog.custom-top-modal.v-dialog--active.v-dialog--persistent.v-dialog--scrollable {
  top: 15px !important;
}
</style>
